@tailwind base;

@tailwind components;

@tailwind utilities;

* {
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
}

.thin-container {
    @apply mx-auto px-4;
    width: 100%;
    max-width: 960px;
}

/* Text */
.h1 {
    @apply text-3xl text-white font-semibold tracking-wide;
}
.h2 {
    @apply text-3xl font-semibold tracking-wide;
}
.h3 {
    @apply text-2xl font-semibold tracking-normal;
}
.h4 {
    @apply text-xl font-semibold tracking-normal;
}

p, li, a {
    @apply text-lg text-white leading-loose;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }
    .h2 {
        @apply text-4xl;
    }
    .h3 {
        @apply text-3xl;
    }
    .h4 {
        @apply text-2xl;
    }
    p, li, a {
        @apply text-xl;
    }
}

strong {
    @apply font-semibold;
}

.dark-text h2,
.dark-text h3,
.dark-text p,
.dark-text a,
.dark-text li {
    @apply text-darkBlue;
}

.highlighted {
    @apply border-b-4 border-yellow;
}

/* Specific Styles */
.resume-bg {
    background-size: 250% auto;
}

.follow-link,
.dark-text .follow-link {
    @apply font-semibold uppercase text-lg bg-no-repeat bg-right pr-8 duration-100;
    background-image: url('assets/img/icons/arrow-right.svg');
    transition-property: padding;
}


/* Button */
.button, a.button {
    @apply inline-block transition duration-100 py-2 px-8 font-semibold rounded-sm mt-8 bg-yellow text-darkBlue;
}
.button.border a.button.button.border {
    @apply border-2 border-yellow text-yellow bg-transparent;
}

/* Responsive Styles */

@screen md {
    .follow-link:hover,
    .dark-text .follow-link:hover {
        @apply pr-10;
    }

    .button:hover, a.button:hover {
        @apply bg-yellow text-darkBlue;
    }
    
}